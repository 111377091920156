<script>
	import { createBubbler, preventDefault } from 'svelte/legacy';

	const bubble = createBubbler();
	/**
	 * @typedef {Object} Props
	 * @property {import('svelte').Snippet} [children]
	 */

	/** @type {Props & { [key: string]: any }} */
	let { children, ...rest } = $props();
</script>

<button onclick={preventDefault(bubble('click'))} {...rest}> {@render children?.()} </button>

<style>
	button {
		background: rgb(255, 102, 0);
		color: #fff;
		padding: 8px 10px;
		border-radius: 3px;
		margin-right: 10px;
		/* font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif; */
		font-weight: bold;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;

		&:disabled {
			background: #ccc;
			opacity: 0.5;
			color: #666;
			cursor: not-allowed;
		}
	}

	.button-smaller {
		padding: 4px 8px;
	}
</style>
